import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,RouterEvent,Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { FlamelinkService } from '../shared/services/flamelink.service';
import { trigger, state, transition, animate,style } from '@angular/animations';
import { fade } from '../animations';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  animations : [
    fade
  ]


})
export class ServiceComponent implements OnInit {
  service: any = {};
  service2 = [];
  bannerimage;
  constructor(private route: ActivatedRoute, private _fl: FlamelinkService,private router: Router) {



    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          this.service2 = [];
      }

      if (event instanceof NavigationEnd) {
          this.fetchData();
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator
          // Present error to user
          console.log(event.error);
      }
  });






    
   }
  app = this._fl.getApp();
  id = this.route.snapshot.params.id;


  fetchData(){
    this.id = this.route.snapshot.params.id;
    this.app.content.get('services', this.id, { populate: ['banner'] })
      .then(data => {

        this.service = data;
        this.service.image =this.service.banner[0].url;
        this.service2.push(data);
      })
      .catch(err => {
        console.log(err)
      })
  }



  ngOnInit() {

  }




}
