import { Component, OnInit } from '@angular/core';
import {} from "googlemaps";
import { ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  submitted = false;
  done = false;
  biodata:any;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  constructor(public afs: AngularFirestore) { }

feedback(form){
  this.submitted = true;
  this.biodata = {
   name:form.value.name,
   email:form.value.email,
   message:form.value.message,
   date: Date().toString()
  }
  this.afs.collection('feedback').add(this.biodata).then(res => {
    this.done = true;
    console.log(res)
  })
    .catch(err => {
      console.log(err);
    })
}


ngOnInit(): void {
   const mapProperties = {
     
        center: new google.maps.LatLng(-1.2952128336170916, 36.79912217254537),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
   };
   this.map = new google.maps.Map(this.mapElement.nativeElement,mapProperties);
}


}
