import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  services = [];
  constructor(private _fl: FlamelinkService) { }
  app = this._fl.getApp();

  carouselOptions = {
    
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    margin:25,
    nav: true,
    dots: true,
    loop:true,
    responsiveClass: true,
    lazyLoad:false,
    items:4,
    autoplay: true,
    smartSpeed: 1500,
    responsive: {
        0:{
          items: 3
        },
        480:{
          items:4
        },
        769:{
          items: 6
        }
    }
  }




  
  ngOnInit() {
    
console.log("services")
    this.app.content.get('services', { populate: [ 'icon','introduction','contentck' ] })
      .then(data => {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            console.log(data[property]);
            this.services.push(data[property]);
          }
          else{
            console.log("no data")
          }
        }
       console.log(this.services);
      })
      .catch(err => {
         console.log(err);
      })
  }

}
