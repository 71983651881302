import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  reviews = [];
  app = this.flamelink.getApp();
 
  constructor(private flamelink: FlamelinkService,private sanitizer: DomSanitizer) { }
  carouselOptions = {
    loop:true,
    dots:true,
    lazyLoad:false,
    items:3,
    autoplay: false,
    smartSpeed: 1500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1500: {
        items: 3,
      }
    }
  }

  ngOnInit() {
    this.app.content.get('testimonials')
      .then(data => {
        
       for(var val in data){
        if(data.hasOwnProperty(val)){
          this.reviews.push(data[val])
        }
       }
      })
      .catch(err => {
       console.log(err);
      })
  }

}
