import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  app = this._fl.getApp();
  services = [];
  submitted = false;
  done = false;
  biodata: any;

  constructor(public afs: AngularFirestore,private _fl: FlamelinkService) { }



  appointment(form){
    this.submitted = true;
    this.biodata = {
      name: form.value.name,
      email: form.value.email,
      phone: form.value.phone,
      address: form.value.address,
      emergencyContact: form.value.emergencyContact,
      details: form.value.details,
      date: Date().toString()
    }
  
    console.log(this.biodata);

    this.afs.collection('appointments').add(this.biodata).then(res => {
      this.done = true;
      // console.log(form.value);
      console.log(res)
    })
      .catch(err => {
        console.log(err);
      })


  }













  ngOnInit() {

    this.app.content.get('services', { populate: [ 'homeImage','bannerImage' ] })
    .then(data => {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          //console.log(data[property]);
          this.services.push(data[property]);
        }
      }
   //   console.log(this.services);
    })
    .catch(err => {
      // console.log(err);
    })

  }

}
