import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { fade } from '../animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [
    fade
  ]
})
export class AboutComponent implements OnInit {
  intro = {};
  about = {};
  img: any;
  introImg: any;
  sliderImages = [];
  showbanner: boolean = true;
  holder = [];
  carouselOptions = {
    lazyLoad: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    margin: 0,
    smartSpeed: 10000,
    autoplay: true,
    loop: true,
    dots: false,
    items: 1,
    autoHeight: false
  }

  constructor(private _fl: FlamelinkService) { }
  app = this._fl.getApp();
  ngOnInit() {
    this.app.content.get('mainSlider', { populate: ['image'] })
      .then(data => {
        for (var property in data) {
          console.log()
          

          this.app.storage.getURL(data[property].image[0].id,{
            size : {
             width: 1080,
             quality: 1
            }

           }).then(data=>{
             this.sliderImages.push(data)
           })



        }
      })
      .catch(err => {})
  }

}
