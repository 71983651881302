import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { LandingComponent } from './landing/landing.component';
import { ServiceComponent } from './service/service.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component'
const routes: Routes = [
  {path: '', component: LandingComponent},
  {path:'service', component: ServiceComponent},
  {path:'contact', component: ContactComponent},
  {path: 'about', component: AboutComponent},
  {path: 'service/:id',component: ServiceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
