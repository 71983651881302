import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  partners = [];
  constructor(private _fl: FlamelinkService) { }
  app = this._fl.getApp();


  carouselOptions = {
    margin:25,
    nav: true,
    dots: false,
    loop:true,
    responsiveClass: true,
    lazyLoad:false,
    items:1,
    autoplay: true,
    smartSpeed: 1500,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 5,
      },
      1000: {
        items: 6,
      },
      1500: {
        items: 8,
      }
    }
  }



  ngOnInit() {

    this.app.content.get('inspar', { populate: [ 'logo' ] },)
      .then(data => {
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
             
            data[property].logo.forEach(element => {
              this.app.storage.getURL(element.id,{
               size : {
                width: 720,
                quality: 0.75
               }

              }).then(data=>{
                this.partners.push(data)
              })
            });
            
          } else {
            console.log("no images found");
          }
        }

      })
      .catch(err => {
        // console.log(err);
      })

  }

}
