import { Component, OnInit } from '@angular/core';
import { FlamelinkService } from '../shared/services/flamelink.service';
import { trigger, state, transition, animate,style } from '@angular/animations';
import { fade } from '../animations';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  animations : [
    fade
  ]
})
export class LandingComponent implements OnInit {
  intro = {};
  about = {};
  img: any;
  introImg: any;
  sliderImages = [];
  showbanner: boolean = true;
 holder = [];
  carouselOptions = {
    lazyLoad:true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    margin: 0,
    smartSpeed: 5000,
    autoplay: true,
    loop: true,
    dots: false,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:1,
      },
      1000:{
          items:1,
      }
    }
  }


  constructor(private _fl: FlamelinkService) { }
  app = this._fl.getApp();
  ngOnInit() {


    this.app.content.get('mainSlider', { populate: ['image'] })
      .then(data => {
        for (var property in data) {
          this.holder.push(data[property].image);
        }
        for(var i = 0; i < this.holder.length; i++){
          this.sliderImages.push({
            url: this.holder[i][0].url
          });

          setTimeout(() => {
            this.showbanner = false;
          }, 500);



        }



      })
      .catch(err => {

      })

    /*
      this._fl.getApp().content.subscribe('aboutOneHealth', (error, data) => {
     if (error) {
      console.error(error);
     }
     this.about = Object.keys(data).map(key => data[ key ]);
     this.about = this.about[1];
   });  
     */


  }

}
